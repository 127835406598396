<template>
  <!--  日清周清：润版液-->
  <div>
    <div class="section">
      <condition :historyQuery = "historyQuery" :enterpriseList="enterpriseList" :conditionFrom="conditionFrom" :isMultiple="true" @search="search"></condition>
    </div>

    <div class="table-box content" style="padding: 0 0 0 20px">
      <div v-if="showDisplay">
        <div v-for="(item,index) in formList" :key="index">
          <div style="display: flex;justify-content: space-between;align-items: baseline;">
            <h2>{{ item.name }}</h2>
            <el-button
                style="margin-right: 20px"
                type="primary"
                @click="handleDetails(item)"
            >
              查看明细
            </el-button>
          </div>
          <div>
            <div v-for="(line, i) in item.deviceList" :key="i" style="height: 400px;display: inline-block;border: 1px solid #e4e7ed;margin: 0px 10px 20px; ">
              <div style="width: 460px; ">
                <div class="title chart">
                  <div class="deviceName">{{ line.deviceName }}</div>
                  <el-button  v-if='line.deviceList.length > 1' class="right-center" type="primary" @click="handleCheck(line)">
                    查看大图
                  </el-button>
                </div>

                <div style="position: relative">
                  <RingChart
                    v-if="line.deviceList.length > 0"
                    :id="`chart2${index}${i}`"
                    :data="line.deviceList"
                    :legendRight="'auto'"
                    :legendTop="'90%'"
                    :center="['50%', '50%']"
                    :height="'330px'"
                    :total="line.count"
                    :totalTop="'40%'"
                    :legendFontSize="15"
                  ></RingChart>
                  <div v-else style="text-align: center; display: flex; position: absolute;margin-left: 220px;margin-top: 135px; justify-content: center; flex-direction: column; align-items: center;">暂无数据</div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog :title="titleBig" width="50%" height="50%" @close="handleClose" :visible.sync="dialogTableVisibleBig" :modal-append-to-body="false" :append-to-body="false" :close-on-click-modal="false">
      <div>
        <!--          v-if="line.deviceList.length > 0"-->
        <dialogDig :formListBig="formListBig"></dialogDig>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import API from '@/api';
import RingChart from "@/components/ChartsTime/RingChart";
import condition from './condition'
import dialogDig from './dialogDig'
export default {
  name: "fountainSolution",
  components: {RingChart, condition, dialogDig},
  props: {
    enterpriseList: {
      type: Array,
      default: () => []
    },
    conditionFrom: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      teamId: [],
      filter: {
        startTime: undefined,
        endTime: undefined,
        teamIds: []
      },
      showDisplay: false,
      options: {
        status: [],
      },
      tableData: [],
      pickerOptions: {
        disabledDate: time => {
          if (this.filter.endTime) {
            return (
              time.getTime() > new Date(this.filter.endTime).getTime() || time.getTime() < new Date(this.filter.endTime).getTime() - 100 * 24 * 3600000 || time.getTime(new Date()) > Date.now()
            );
          }
          else{
            return time.getTime(new Date()) > Date.now();
          }
        }
      },
      pickerOptionsEnd:{
        disabledDate: time => {
          if (this.filter.startTime) {
            return (
              time.getTime() < new Date(this.filter.startTime).getTime() - 8.64e7 || time.getTime() > new Date(this.filter.startTime).getTime() + 100 * 24 * 3600000 || time.getTime(new Date()) > Date.now()
            );
          }
          else{
            return time.getTime(new Date()) > Date.now();
          }
        }
      },
      formData: [],
      formList: [],
      total1: 0, // 环形图数据-当日报警-总次数
      number: 0,
      // 环形图颜色
      // chartColor: ['#5DB1FF', '#59D4D4', '#6ACE88'],
      // 环形图数据-当日报警
      chartData1: [
        {value: 0, name: "PH值"},
        {value: 0, name: "水温"},
        {value: 0, name: "电导率"},
      ],
      formListBig: {
        deviceList: []
      },
      dialogTableVisibleBig: false,
      titleBig: '',
      historyQuery:{}
    };
  },
  mounted() {

  },
  created() {
  },
  methods: {
    handleDetails() {
      this.$router.push({ path: 'fountainSolutionDetails', query: { ...this.filter, activeName:this.activeName } });
    },
    startTimeChange(val){
      if(this.filter.endTime && new Date(val).getTime() > new Date(this.filter.endTime).getTime()){
        this.filter.startTime = null
      }else{
        this.filter.startTime = val
      }
    },
    endTimeChange(val){
      if(this.filter.startTime && new Date(val).getTime() < new Date(this.filter.startTime).getTime()){
        this.filter.endTime = null
      }else{
        this.filter.endTime = val
      }
    },
    handleChange(e){
      if(e.length>5){
        this.$message({
          message: '最多只能选择5个公司',
          type: 'warning',
        });
        this.teamId.splice(-1)
      }
    },
    getChart() {
      API.getDampeningSolution({...this.filter}).then(res => {
        // const form = res
        res.message.data.forEach(item => {
          let list = []
          item.deviceList.forEach(line => {
            let data = []
            if ((line.ph === 0) && (line.sw === 0) && (line.dll === 0)) {
              data = []
            } else {
              data = [
                { value: line.ph, name: 'PH值' },
                { value: line.sw, name: '水温' },
                { value: line.dll, name: '电导率' },
              ]
            }
            const tempItem = {
              deviceName: line.deviceName,
              count: line.count,
              deviceList: data
            }
            list.push(tempItem)
          })
          const time = {
            name: item.name,
            deviceList: list
          }
          this.formList.push(time)
        })
        console.log(this.formList)
      })
    },
    // 查询
    search(condition) {
      const conditionFrom = JSON.parse(JSON.stringify(condition))
      if (!this.$store.state.condition.enterprise) {
        // conditionFrom.enterprise = conditionFrom.enterprise[0]
        this.$store.commit('setFormDate',conditionFrom)
      }
      this.formList = []
      this.filter = {
        ...condition.query,
        teamIds:condition.enterprise,
      }
      this.historyQuery = {
        startTime: condition.query.startTime,
        endTime: condition.query.endTime,
        enterprise: condition.enterprise
      }
      this.getChart()
      this.showDisplay = true
    },
    // 查看大图
    handleCheck(item) {
      this.formListBig = item
      this.formListBig.title = Math.floor(Math.random()*(100-1) + 1)
      this.titleBig = item.deviceName
      this.dialogTableVisibleBig = true
    },
    handleClose() {
      this.formListBig = {}
      this.formListBig.deviceList = []
    },
    // 每页显示个数改变时会触发
    handleSizeChange(val) {
      this.filter.count = val;
      this.getList();
    },
    // 当前页数改变时会触发
    handleCurrentChange(val) {
      this.filter.page = val;
      this.getList();
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep .el-tabs--card > .el-tabs__header {
  border-bottom: 0px solid #e4e7ed;
}
.right-center{
  margin: auto 0;
}
.content {
  height: calc(100vh - 350px);
  overflow: auto;

  .chart {
    width: 100%;
    //height: 400px;
    padding: 10px 20px;
    .title {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .deviceName{
        width: 73%;
        line-height: 36px;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
      }
    }

    .echatsImg {
      width: 100%;
      height: 300px;
    }
  }
}
.title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .deviceName{
    width: 73%;
    line-height: 36px;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
  }
}
.control-box{
  margin-bottom: 0;
}
h2 {
  margin: 10px 10px 18px;
}
</style>
