<template>
  <!--  日清周清：保养&点检-->
  <div>
    <div class="section">
      <condition :historyQuery = "historyQuery" :enterpriseList="enterpriseList" :conditionFrom="conditionFrom" @search="search"></condition>
    </div>

    <div class="content" v-if="showDisplay" style="padding: 0 0 0 20px">
      <div class="table-box" >
        <div style="margin-bottom: 20px;font-size: 16px;font-weight: bold">保养&点检达成率</div>
        <el-table
          :data="maintainData"
          border
          style="width: 100%;"
          height="300"
        >
          <el-table-column
            prop="name"
            label="设备名"
            align="left"
          ></el-table-column>
          <el-table-column
            prop="maintainDay"
            label="保养日保"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="checkDay"
            label="点检日保"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="maintainWeek"
            label="保养周保"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="checkWeek"
            label="点检周保"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="maintainMonth"
            label="保养月保"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="checkMonth"
            label="点检月保"
            align="center"
          ></el-table-column>
        </el-table>
      </div>
      <div class="table-box">
        <div style="margin-bottom: 20px;font-size: 16px;font-weight: bold">得分排名</div>
        <el-tabs v-model="activeName" :tab-position="tabPosition" type="card" @tab-click="handleClick">
          <el-tab-pane label="日保" name="first">
            <el-table
              :data="scoreData[0].scoreList"
              border
              style="width: 100%;"
              height="300"
            >
              <el-table-column
                prop="cPhone"
                label="排名"
                align="center"
                type="index"
              ></el-table-column>
              <el-table-column
                prop="name"
                label="设备名"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="person"
                label="保养人"
                align="center"
              ></el-table-column><el-table-column
                prop="score"
                label="得分"
                align="center"
              ></el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="周保" name="second">
            <el-table
              :data="scoreData[1].scoreList"
              border
              style="width: 100%;"
              height="300"
            >
              <el-table-column
                prop="cPhone"
                label="排名"
                align="center"
                type="index"
              ></el-table-column>
              <el-table-column
                prop="name"
                label="设备名"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="person"
                label="保养人"
                align="center"
              ></el-table-column><el-table-column
              prop="score"
              label="得分"
              align="center"
            ></el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="半月保" name="six">
            <el-table
                :data="scoreData[2].scoreList"
                border
                style="width: 100%;"
                height="300"
            >
              <el-table-column
                  prop="cPhone"
                  label="排名"
                  align="center"
                  type="index"
              ></el-table-column>
              <el-table-column
                  prop="name"
                  label="设备名"
                  align="center"
              ></el-table-column>
              <el-table-column
                  prop="person"
                  label="保养人"
                  align="center"
              ></el-table-column><el-table-column
                prop="score"
                label="得分"
                align="center"
            ></el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="月保" name="third">
            <el-table
              :data="scoreData[3].scoreList"
              border
              style="width: 100%;"
              height="300"
            >
              <el-table-column
                prop="cPhone"
                label="排名"
                align="center"
                type="index"
              ></el-table-column>
              <el-table-column
                prop="name"
                label="设备名"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="person"
                label="保养人"
                align="center"
              ></el-table-column><el-table-column
              prop="score"
              label="得分"
              align="center"
            ></el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="半年保" name="fourth">
            <el-table
              :data="scoreData[4].scoreList"
              border
              style="width: 100%;"
              height="300"
            >
              <el-table-column
                prop="cPhone"
                label="排名"
                align="center"
                type="index"
              ></el-table-column>
              <el-table-column
                prop="name"
                label="设备名"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="person"
                label="保养人"
                align="center"
              ></el-table-column><el-table-column
              prop="score"
              label="得分"
              align="center"
            ></el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="年保" name="five">
            <el-table
              :data="scoreData[5].scoreList"
              border
              style="width: 100%;"
              height="300"
            >
              <el-table-column
                prop="cPhone"
                label="排名"
                align="center"
                type="index"
              ></el-table-column>
              <el-table-column
                prop="name"
                label="设备名"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="person"
                label="保养人"
                align="center"
              ></el-table-column><el-table-column
              prop="score"
              label="得分"
              align="center"
            ></el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <!--分页-->
<!--    <div class="pagination-box">-->
<!--      <el-pagination-->
<!--        v-show="filter.total>0"-->
<!--        :current-page="filter.page"-->
<!--        :page-sizes="[10, 20, 30, 40]"-->
<!--        :page-size="filter.count"-->
<!--        :total="filter.total"-->
<!--        layout="total, sizes, prev, pager, next, jumper"-->
<!--        @size-change="handleSizeChange"-->
<!--        @current-change="handleCurrentChange"-->
<!--      />-->
<!--    </div>-->
  </div>
</template>

<script>
import API from '@/api';
import condition from './condition'
export default {
  name: "maintainPoint.vue",
  components: { condition },
  props: {
    enterpriseList: {
      type: Array,
      default: () => []
    },
    conditionFrom: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      showDisplay: false,
      filter: {
        startTime: undefined,
        endTime: undefined,
        teamId: ''
      },
      teamId: '',
      options: {
        status: [],
      },
      maintainData: [],
      scoreData: [],
      pickerOptions: {
        disabledDate: time => {
          if (this.filter.endTime) {
            return (
              time.getTime() > new Date(this.filter.endTime).getTime() || time.getTime() < new Date(this.filter.endTime).getTime() - 100 * 24 * 3600000 || time.getTime(new Date()) > Date.now()
            );
          }else{
            return time.getTime(new Date()) > Date.now();
          }
        }
      },
      pickerOptionsEnd:{
        disabledDate: time => {
          if (this.filter.startTime) {
            return (
              time.getTime() < new Date(this.filter.startTime).getTime() - 8.64e7 || time.getTime() > new Date(this.filter.startTime).getTime() + 100 * 24 * 3600000 || time.getTime(new Date()) > Date.now()
            );
          }else{
            return time.getTime(new Date()) > Date.now();
          }
        }
      },
      activeName: 'first',
      tabPosition: 'top',
      historyQuery:{}
    };
  },
  mounted() {

  },
  created() {
  },
  methods: {
    startTimeChange(val){
      if(this.filter.endTime && new Date(val).getTime() > new Date(this.filter.endTime).getTime()){
        this.filter.startTime = null
      }else{
        this.filter.startTime = val
      }
    },
    endTimeChange(val){
      if(this.filter.startTime && new Date(val).getTime() < new Date(this.filter.startTime).getTime()){
        this.filter.endTime = null
      }else{
        this.filter.endTime = val
      }
    },
    getDate() {
      API.getMaintain({...this.filter}).then(res => {
        this.maintainData = res.message.data
      })
      API.getmaintainRanking({...this.filter}).then(res => {
        this.scoreData = res.message.data
      })
    },
    // 查询
    search(condition) {
      if (!this.$store.state.condition.enterprise) {
        console.log(this.$store.state.condition)
        this.$store.commit('setFormDate',condition)
      }
      this.showDisplay = false
      this.filter = {
        ...condition.query,
        teamId:condition.enterprise,
      }
      this.historyQuery = {
        startTime: condition.query.startTime,
        endTime: condition.query.endTime,
        enterprise: condition.enterprise
      }
      this.getDate()
      this.showDisplay = true
    },
    handleClick(tab, event) {
      console.log(tab);
    },
    // 每页显示个数改变时会触发
    // handleSizeChange(val) {
    //   this.filter.count = val;
    //   this.getList();
    // },
    // // 当前页数改变时会触发
    // handleCurrentChange(val) {
    //   this.filter.page = val;
    //   this.getList();
    // },
  },
};
</script>
<style scoped lang="scss">
::v-deep .el-tabs--card > .el-tabs__header{
  border-bottom: 0px solid #e4e7ed;
}
.content{
  height: calc(100vh - 290px);
  overflow: auto;
  .chart{
    width: 94%;
    height: 500px;
    .title{
      display: flex;
      justify-content: space-between;
    }

    .echatsImg{
      width: 100%;
      height: 300px;
    }
  }
}
.content ::v-deep .el-tabs__nav{
  height: 50px !important;
  display: inline-flex;
  align-items: center;
}
.content ::v-deep .el-tabs__item{
  //height: 11.11%;
  height: 50px !important;
  width: 100%;
  display: flex;
  align-items: center;
}
.content ::v-deep .el-tabs__item:last-child{
  border-right: 1px solid #e4e7ed;
}
//height: calc(100vh - 50px);
.content .section{
  height: 100%;
}
::v-deep .table-box table thead th .cell{
  color: #606266;
}
</style>
