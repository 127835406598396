<template>
  <div class="HomePage">
    <div :id="EchatsId" class="EchatsImg"></div>
  </div>
</template>

<script>
export default {
  // 定义js方法给flutter调用
  // postMessageToFlutter(url) {
  //   // console.log(url)
  //   window.postMessage(url,'*')
  // },
  props: {
    EchatsId: {
      type: [String, Number],
      default: 'chart',
    },
    deviceListData: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {
    this.myEcharts();
    window.postMessageToFlutter = this.postMessageToFlutter;
  },
  methods: {
    scoreResultColor: (result) => {
      if (result === 0) {
        return '#EA000E';
      }
      if (result === 1) {
        return '#FF9900';
      }
      if (result === 2) {
        return '#2187E4';
      }
      if (result === 3) {
        return '#37C239';
      }
    },
    // postMessageToFlutter(url){
    //   window.postMessage(url,'*');
    // },
    myEcharts() {
      var myChart = this.$echarts.init(document.getElementById(this.EchatsId));
      const result = [];
      const Xdata = [];
      this.deviceListData.timeList.map((item) => {
        result.push(item.result + 1);
        Xdata.push(item.time);
        // return Xdata.push(item.time);
      });
      // console.log(time)
      const option = {
        // 标题
        title: {
          text: '',
        },
        tooltip: {
          trigger: 'axis',
          confine: true, // 限制在图表区域内
        },
        // 图例
        legend: {
          show: true,
          itemWidth: 6,
          itemHeight: 6,
          textStyle: {
            // color: '#ffffff'
          },
          orient: 'horizontal',
          bottom: '0%',
        },
        // 数据集
        // dataset: {
        //   source: ,
        // },
        // 图表区域大小
        grid: {
          top: 30,
          left: 0,
          right: 0,
          bottom: 25,
          containLabel: true, // 包含标签
        },
        // X轴
        xAxis: {
          type: 'category',
          axisLabel: {
            color: '#281315',
            show: true,
          },
          // 刻度线
          axisTick: {
            show: false,
          },
          data: Xdata
        },
        // y轴
        yAxis: {
          type: 'value',
          name: '',
          offset: 0, // 偏移
          position: 'left', // 位置
          min: 0, // y轴的最小值
          max: 4, // y轴最大值
          axisLabel: {
            formatter(value) {
              if (value <= 0) {
                return '';
              } else if (value <= 1) {
                return '差';
              } else if (value <= 2) {
                return '中';
              } else if (value <= 3) {
                return '良';
              } else {
                return '优';
              }
            },
            color: '#281315'
          },
          nameTextStyle: {
            // color: '#fff'
          },
          // 刻度线
          axisTick: {
            show: false,
          },
          // 坐标轴线
          axisLine: {
            show: true,
          },
          // x轴网格线颜色
          splitLine: {},
        },
        color: ['#FFC73E'],

        series: [
          {
            name: this.deviceListData.deviceName,
            type: 'bar',
            barMaxWidth: '30px',
            data: result
          }
        ]
      };
      myChart.setOption(option);
      // 图表的自适应
      window.addEventListener('resize', () => {
        myChart.resize();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
//.HomePage {
//  margin-top: 15px;
//  width: 100%;
//  height: 261px;
//  background: #ffffff;
//  box-shadow: 1px 2px 9px 1px rgba(215, 215, 215, 0.6);
//  border-radius: 4px 4px 4px 4px;
//  opacity: 1;
//  padding: 10px;
//}
//.headerBox {
//  display: flex;
//  justify-content: space-between;
//  .content {
//    line-height: 24px;
//    font-size: 14px;
//    color: #281315;
//  }
//  .content1 {
//    font-size: 12px;
//    color: #281315;
//    text-align: center;
//  }
//  .pingfen {
//    font-size: 20px;
//    font-weight: bold;
//  }
//  .contentText {
//    max-width: 200px;
//    //word-break: break-all;
//    font-weight: bold;
//    white-space: nowrap;
//    overflow: hidden;
//    text-overflow: ellipsis;
//  }
//}
//.topBox {
//  display: flex;
//  justify-content: space-between;
//  font-size: 12px;
//  padding-bottom: 15px;
//  border-bottom: 4px solid #dddddd;
//}
</style>
<style>
.EchatsImg {
  width: 680px;
  height: 300px;
}
</style>
