<template>
  <div class="printing-count">
    <div class="section">
        <condition :historyQuery = "historyQuery" :enterpriseList="enterpriseList" :conditionFrom="conditionFrom" :showType="true" :isMultiple="true" @search="search"></condition>
        <div class="content" v-if="data.length">
            <div class="chart" v-for="(item,index) in data">
                <div class="title">
                  <h2>{{item.name}}</h2>
                  <el-button v-if="item.deviceList.length && item.deviceList[0].valueList.length" class="right-center" type="primary" @click="handleDetail(item,index)">
                      查看详情
                  </el-button>
                </div>
                <div v-if="item.deviceList.length && item.deviceList[0].valueList.length" :id="`selected${selectedSheetType}enterprise${index}`" class="echatsImg"></div>
                <div v-else>{{"暂无数据"}}</div>
            </div>
        </div>

    </div>
    <detail-dialog
      :dialogVisible.sync="dialogVisible"
      :data="tableData"
      :sheetType="selectedSheetType"
      @close="dialogClose"
    ></detail-dialog>
  </div>
</template>
<script>
import condition from './condition'
import detailDialog from './detailDialog'
import API from '@/api';

export default {
    components: {
      condition,
      detailDialog
    },
    props: {
      selectedSheetType: Number,
      enterpriseList: {
        type: Array,
        default: () => []
      },
      conditionFrom: {
        type: Object,
        default: () => {}
      }
    },
    data() {
        return {
            data: [],
            dialogVisible: false,
            tableData:{},
            historyQuery:{}
        };
    },
    created() {
    },
    beforeDestroy(){
      this.data = []
    },
    methods: {
        dialogClose(){
          this.dialogVisible = false
        },
        handleDetail(item,index){
          this.dialogVisible = true
          this.tableData = this.data[index]
        },
        search(condition){
          console.log(condition)
          const conditionFrom = JSON.parse(JSON.stringify(condition))
          if (!this.$store.state.condition.enterprise) {
            // conditionFrom.enterprise = conditionFrom.enterprise[0]
            this.$store.commit('setFormDate',conditionFrom)
            console.log(this.$store.state.condition)
          }
          this.data = []
          let param = {
            ...condition.query,
            teamIds:condition.enterprise,
            sheetType: this.selectedSheetType
          }
          this.historyQuery = {
            startTime: condition.query.startTime,
            endTime: condition.query.endTime,
            enterprise: condition.enterprise
          }
          API.getPrintVolume(param).then(res =>{
            console.log(res)
            if(res.message.data){
              this.data = res.message.data
              this.$nextTick(()=>{
                for(let i=0; i< this.data.length; i++){
                  if(this.data[i].deviceList.length && this.data[i].deviceList[0].valueList.length){
                    this.myEcharts(`selected${this.selectedSheetType}enterprise${i}`, i);
                  }
                }
              })
            }
          })
        },
        myEcharts(id, index) {
          console.log(id)
          this.$echarts.init(document.getElementById(id)).dispose()
          let myChart = this.$echarts.init(document.getElementById(id));
          let xData = []
          let dateData = []
          let series = []
          let legend = []
          let len = this.data[index].deviceList.length
          if (len) {
            for (let i=0; i< len; i++){
              let item = this.data[index].deviceList[i]
              legend.push(item.name)
              if(i===0){
                item.valueList.map(record => {
                  xData.push(record.time);
                  dateData.push(record.date)
                });
              }
              let newData = []
              for (let k=0;k<dateData.length;k++) {
                let flag=false
                for (let j=0;j<item.valueList.length;j++) {
                  let subItem = item.valueList[j]
                  if(dateData[k]==subItem.date){
                    newData[k] = {date: subItem.date, time: subItem.time, value: subItem.value, data: null};
                    flag= true
                    break
                  }
                }
                if(!flag){
                  newData[k] = {date: dateData[k], value: null, data: null};
                }
              }
              series[i]={
                type:"bar",
                // barMinWidth: '5px',
                name: item.name,
                data: newData
              }
            }
            console.log(series)
          }
          let option = {
            // 标题
            title: {
              text: "",
            },
            tooltip: {
              trigger: "axis",
              confine: true, // 限制在图表区域内
              // triggerOn: 'none',
              formatter: function (params) {
                let relVal = params.length ? params[0].data.date : "";
                for (let i = 0, l = params.length; i < l; i++) {
                  relVal += `<br/>${params[i].marker}<span style="display:inline-block;width:500px;">${params[i].seriesName}</span> <span>${params[i].value || '-'}</span> `;
                }
                return relVal;
              }
            },
            // 图例
            legend: {
              data: legend,
              bottom: "50",
              type: 'scroll'
            },
            // 图表区域大小
            grid: {
              top:30,
              left: 0,
              right: 10,
              bottom: 80,
              containLabel: true, // 包含标签
            },
            // X轴
            xAxis: {
              type: "category",
              // interval: 100,
              data: xData
            },
            // y轴
            yAxis: {
              type: "value",
              // splitLine: {
              //   lineStyle: {
              //     type: "dashed",
              //     color: '#aaa'
              //   }
              // },
            },
            dataZoom: [
              {
                type: 'inside',
                start: 0,
                end: 100
              },
              {
                start: 0,
                end: 100
              }
            ],
            series
          };
          //console.log(option.dataset.dimensions)
          this.$nextTick(()=>{
            myChart.setOption(option);
          })

          // 图表的自适应
          window.addEventListener('resize', () => {
            myChart.resize();
          });
        },
    },
};
</script>

<style lang="scss" scoped>
.printing-count{
    .right-center{
      margin: auto 0;
    }
    .content{
      height: calc(100vh - 220px);
      overflow: auto;
        .chart{
          width: 94%;
          height: 400px;
            .title{
              display: flex;
              justify-content: space-between;
            }

            .echatsImg{
              width: 100%;
              height: 300px;
            }
        }
    }
}
h2{
  font-size: 21px;
}
</style>
