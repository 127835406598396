
<template>
  <!--  日清周清：印刷质量-->
  <div>
    <div class="section">
      <condition :historyQuery = "historyQuery" :enterpriseList="enterpriseList" :conditionFrom="conditionFrom" :isMultiple="true" @search="search"></condition>
    </div>

    <div class="table-box content-box" style="padding: 0 0 0 20px">
      <el-tabs v-if="showDisplay" v-model="activeName" type="card" @tab-click="handleClick">
        <el-tab-pane label="质量评分" name="first">
          <div class="content">
            <div v-for="(item,index2) in formList" :key="index2" >
              <h2 class="h2">{{ item.name }}</h2>
              <div v-if="item.deviceList.length < 1">暂无数据</div>
              <div  v-else>
                <div v-for="(line, i) in item.deviceList" :key="i" style="height: 390px;display: inline-block;border: 1px solid #e4e7ed;margin: 0px 10px 20px; ">
                  <div style="width: 460px; ">
                    <div style="display: flex;justify-content: space-between;align-items: center;padding: 20px">
                      <div>
                        <el-tooltip class="item" effect="dark" :content="line.deviceName" placement="top-start">
                          <div style="margin-bottom: 10px;width: 214px; white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">{{ line.deviceName }}</div>
                        </el-tooltip>
                        <div>上传数量:{{ line.count }} 平均分:{{ line.score > 0 ? line.score : '--' }}%</div>
                      </div>
                      <div>
                        <el-button v-if="(line.deviceList.length > 1)" type="primary" @click="handleCheckBig(line)">查看大图</el-button>
                        <el-button v-if="line.deviceId !== null" type="primary" @click="handleCheck(line)">查看详情</el-button>
                      </div>
                    </div>
                    <div style="position: relative">
                      <RingChart2
                        v-if="line.deviceList.length > 0"
                        :id="`chart1${index2}${i}4`"
                        :data="line.deviceList"
                        :legendRight="'auto'"
                        :legendTop="'90%'"
                        :height="'300px'"
                        :total="line.count"
                        :totalTop="'52%'"
                        :legendFontSize="15"
                      ></RingChart2>
                      <div v-else style="text-align: center; display: flex; position: absolute;margin-left: 220px;margin-top: 135px; justify-content: center; flex-direction: column; align-items: center;">暂无数据</div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="放墨精度" name="second">
          <div class="content">
            <div v-for="(item,index6) in editableTabsList" :key="index6">
              <h2>{{ item.name }}</h2>
              <div v-if="!item.deviceList">暂无数据</div>
              <div v-else>
                <el-tabs v-model="editableTabsValue" type="card" @edit="handleTabsEdit">
                  <el-tab-pane
                      :key="i"
                      v-for="(line,i) in item.deviceList"
                      :label="line.deviceName"
                      :name="line.name"
                  >
                  <el-table
                      :data="line.timeList"
                      border
                      style="width: 100%;"
                      height="300"
                    >
                      <el-table-column
                        prop="time"
                        label="时间"
                        align="center"
                      ></el-table-column>
                      <el-table-column
                        prop="c"
                        label="C平均偏差趋势"
                        align="center"
                      >
                        <template slot-scope="{row}">
                          {{row.c}}%
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="m"
                        label="M平均偏差趋势"
                        align="center"
                      >
                        <template slot-scope="{row}">
                          {{row.m}}%
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="y"
                        label="Y平均偏差趋势"
                        align="center"
                      >
                        <template slot-scope="{row}">
                          {{row.y}}%
                        </template>
                      </el-table-column>
                      <el-table-column
                        prop="k"
                        label="K平均偏差趋势"
                        align="center"
                      >
                        <template slot-scope="{row}">
                          {{row.k}}%
                        </template>
                      </el-table-column>
                    </el-table>
                  </el-tab-pane>
                </el-tabs>
              </div>
            </div>
          </div>

        </el-tab-pane>
        <el-tab-pane label="水墨平衡" name="third">
          <div class="content">
            <div v-for="(item,index4) in balanceList" :key="index4">
              <h2>{{item.name}}</h2>
              <div v-if="item.deviceList.length < 1"> 暂无数据</div>
              <div v-else>
                <div v-for="(line, index5) in item.deviceList" :key="index5" style="display: inline-block">
                  <div style="width: 690px;height:320px;display: inline-block;margin-right: 25px">
                    <div style="position: relative">
                      <EchatsList2
                        :EchatsId="`chart${index4}${index5}`"
                        :deviceListData="line"
                      ></EchatsList2>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <el-dialog :title="title" width="60%" :visible.sync="dialogTableVisible" :modal-append-to-body="false" :append-to-body="false" :close-on-click-modal="false">
      <el-table :data="orderData" height="500">
        <el-table-column property="name" align="center" label="订单号" ></el-table-column>
        <el-table-column property="date" label="时间" align="center"></el-table-column>
        <el-table-column property="rateScore" width="160px" label="精度误差" align="center"></el-table-column>
        <el-table-column property="tt_rate" label="总分" align="center"></el-table-column>
        <el-table-column property="name" label="纸张" align="center">
          <template slot-scope="{row}">
            <span>{{row.v2_act}}/{{row.v2_total}}</span>
          </template>
        </el-table-column>
        <el-table-column property="address" label="CMYK" align="center">
          <template slot-scope="{row}">
            <span>{{row.v1_act}}/{{row.v1_total}}</span>
          </template>
        </el-table-column>
        <el-table-column property="date" label="RGB" align="center">
          <template slot-scope="{row}">
            <span>{{row.v3_act}}/{{row.v3_total}}</span>
          </template>
        </el-table-column>
        <el-table-column property="name" label="TVI" align="center">
          <template slot-scope="{row}">
            <span>{{row.v5_act}}/{{row.v5_total}}</span>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog :title="titleBig" width="50%" height="50%" @close="handleClose" :visible.sync="dialogTableVisibleBig" :modal-append-to-body="false" :append-to-body="false" :close-on-click-modal="false">
      <div>
        <!--          v-if="line.deviceList.length > 0"-->
        <dialogDig :formListBig="formListBig"></dialogDig>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import condition from './condition'
import dialogDig from './dialogDig'
import RingChart2 from "@/components/ChartsTime/RingChart2";
import EchatsList2 from "@/components/ChartsTime/EchatsList2";
import API from '@/api';
export default {
  name: "userManagement.vue",
  components: {RingChart2, EchatsList2, condition,dialogDig},
  props: {
    enterpriseList: {
      type: Array,
      default: () => []
    },
    data:{
      type: Object,
      default: () => {},
    },
    conditionFrom: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      editableTabsValue: '',
      editableTabsList: [],
      tabIndex: 2,
      teamId: [],
      filter: {
        startTime: undefined,
        endTime: undefined,
        teamIds: []
      },
      activeName: 'first',
      showDisplay: false,
      options: {
        status: [],
      },
      tableData: [],
      pickerOptions: {
        disabledDate: time => {
          if (this.filter.endTime) {
            return (
              time.getTime() > new Date(this.filter.endTime).getTime() || time.getTime() < new Date(this.filter.endTime).getTime() - 100 * 24 * 3600000 || time.getTime(new Date()) > Date.now()
            );
          }else{
            return time.getTime(new Date()) > Date.now();
          }
        }
      },
      pickerOptionsEnd:{
        disabledDate: time => {
          if (this.filter.startTime) {
            return (
              time.getTime() < new Date(this.filter.startTime).getTime() - 8.64e7 || time.getTime() > new Date(this.filter.startTime).getTime() + 100 * 24 * 3600000 || time.getTime(new Date()) > Date.now()
            );
          }else{
            return time.getTime(new Date()) > Date.now();
          }
        }
      },
      // 水墨平衡
      balanceList: [],
      formData: [],
      formList: [],
      formListBig: {
        deviceList: []
      },
      total1: 0, // 环形图数据-当日报警-总次数
      number: 0,
      dialogTableVisible: false,
      dialogTableVisibleBig: false,
      title: '',
      titleBig: '',
      orderData: [],
      // 环形图颜色
      // chartColor: ['#5DB1FF', '#59D4D4', '#6ACE88'],
      // 环形图数据-当日报警
      chartData1: [
        {value: 0, name: "PH值"},
        {value: 0, name: "水温"},
        {value: 0, name: "电导率"},
      ],
      historyQuery:{}
    };
  },
  mounted() {

  },
  created() {
  },
  methods: {
    startTimeChange(val){
      if(this.filter.endTime && new Date(val).getTime() > new Date(this.filter.endTime).getTime()){
        this.filter.startTime = null
      }else{
        this.filter.startTime = val
      }
    },
    endTimeChange(val){
      if(this.filter.startTime && new Date(val).getTime() < new Date(this.filter.startTime).getTime()){
        this.filter.endTime = null
      }else{
        this.filter.endTime = val
      }
    },
    handleChange(e){
      if(e.length>5){
        this.$message({
          message: '最多只能选择5个公司',
          type: 'warning',
        });
        this.teamId.splice(-1)
      }
    },
    getChart() {
      API.getQualityScore({...this.filter}).then(res => {
        // const form = res
        res.message.data.forEach(item => {
          let list = []
          item.deviceList.forEach(line => {
            let data = []
            if ((line.value1 === '0') && (line.value2 === '0') && (line.value3 === '0') && (line.value4 === '0')) {
              data = []
            } else {
              data = [
                { value: line.value1, name: '0-60' },
                { value: line.value2, name: '60-70' },
                { value: line.value3, name: '70-80' },
                { value: line.value4, name: '80-100' },
              ]
            }
            const tempItem = {
              deviceName: line.deviceName,
              deviceId: line.deviceId,
              score: line.score,
              count: line.count,
              deviceList: data
            }
            list.push(tempItem)
          })
          const time = {
            name: item.name,
            deviceList: list
          }
          this.formList.push(time)
        })
        console.log(this.formList)
      })
      API.getInkAccuracy({...this.filter}).then(res => {
        this.editableTabsList = res.message.data
      })
      this.balanceList = []
      API.getInkBalance({...this.filter}).then(res => {
        this.balanceList = res.message.data
      })
    },
    // 查询
    search(condition) {
      const conditionFrom = JSON.parse(JSON.stringify(condition))
      if (!this.$store.state.condition.enterprise) {
        // conditionFrom.enterprise = conditionFrom.enterprise[0]
        this.$store.commit('setFormDate',conditionFrom)
      }
      this.formList = []
      this.balanceList = []
      this.filter = {
        ...condition.query,
        teamIds:condition.enterprise,
      }
      this.historyQuery = {
        startTime: condition.query.startTime,
        endTime: condition.query.endTime,
        enterprise: condition.enterprise
      }
      this.getChart()
      this.showDisplay = true
    },

    // handleQuery() {
    //   this.formList = []
    //   this.balanceList = []
    //   if(!this.filter.startTime){
    //     this.$message({
    //       message: '请选择开始日期',
    //       type: 'warning',
    //     });
    //     return;
    //   }
    //   if(!this.filter.endTime){
    //     this.$message({
    //       message: '选择结束日期',
    //       type: 'warning',
    //     });
    //     return;
    //   }
    //   if(!this.teamId.length){
    //     this.$message({
    //       message: '请选择公司',
    //       type: 'warning',
    //     });
    //     return;
    //   }
    //   this.filter.teamIds = this.teamId.join(',')
    //   this.getChart()
    //   this.showDisplay = true
    // },
    // 查看大图
    handleCheckBig(item) {
      this.formListBig = item
      this.formListBig.deviceId = item.deviceId + "2"
      this.titleBig = item.deviceName
      this.dialogTableVisibleBig = true
    },
    handleClose() {
      this.formListBig = {}
      this.formListBig.deviceList = []
    },
    // 查看详情
    handleCheck(item) {
      API.getQualityScoreDetail({startTime:this.filter.startTime,endTime:this.filter.endTime,deviceId:item.deviceId}).then(res => {
        this.orderData = res.message.data
      })
      this.title = item.deviceName
      this.dialogTableVisible = true
      // this.$router.push({ path: 'regional', query: { id: item.id }})
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    handleTabsEdit(tab, event) {
      // console.log(tab, event)
    },
    // 每页显示个数改变时会触发
    handleSizeChange(val) {
      this.filter.count = val;
      this.getList();
    },
    // 当前页数改变时会触发
    handleCurrentChange(val) {
      this.filter.page = val;
      this.getList();
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep .el-tabs--card > .el-tabs__header {
  border-bottom: 0px solid #e4e7ed;
}

.right-center{
  margin: auto 0;
}

.content {
  height: calc(100vh - 450px);
  overflow: auto;


  .chart {
    width: 94%;
    height: 400px;

    .title {
      display: flex;
      justify-content: space-between;
    }

    .echatsImg {
      width: 100%;
      height: 300px;
    }
  }
}
.control-box{
  //margin-bottom: 0;
}
.content-box ::v-deep .el-tabs__nav{
  height: 50px !important;
  display: inline-flex;
  align-items: center;
}
.content-box ::v-deep .el-tabs__item{
  //height: 11.11%;
  height: 50px !important;
  width: 100%;
  display: flex;
  align-items: center;
}
.content-box ::v-deep .el-tabs__item:last-child{
  border-right: 1px solid #e4e7ed;
}
//height: calc(100vh - 50px);
.content-box .section{
  height: 100%;
}
::v-deep .el-table .cell {
  white-space: pre-line;
}
.h2 {
  margin: 10px 10px 18px;
}
h2 {
  margin: 10px 0px 18px;
}
::v-deep .table-box table thead th .cell{
  color: #606266;
}
::v-deep .el-table th.el-table__cell > .cell {
  color: #606266;
}
</style>
