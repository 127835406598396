<template>
    <div class="control-box control-box-width">
        <div class="left">
            <el-date-picker
              v-if="dateStart"
                class="item-component"
                v-model="query.startTime"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择开始日期"
                :picker-options="pickerOptionsStart"
                @change="startTimeChange"
            >
            </el-date-picker>
          <el-date-picker
            v-if="dateTime"
            class="item-component"
            v-model="query.date"
            type="date"
            value-format="yyyy-MM-dd"
            placeholder="选择日期"
            :picker-options="optionsStart"
          >
          </el-date-picker>
            <el-date-picker
              v-if="dateStart"
                class="item-component ml10"
                v-model="query.endTime"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择结束日期"
                :picker-options="pickerOptionsEnd"
                @change="endTimeChange"
            >
            </el-date-picker>
<!--            <el-select-->
<!--                class="item-component-select ml10"-->
<!--                v-model="enterprise"-->
<!--                :multiple="isMultiple"-->
<!--                clearable-->
<!--                placeholder="请选择公司"-->
<!--                @change="handleChange"-->
<!--                filterable-->
<!--            >-->
<!--                <el-option-->
<!--                    v-for="item in enterpriseList"-->
<!--                    :key="item.id"-->
<!--                    :label="item.cName"-->
<!--                    :value="item.id"-->
<!--                />-->
<!--            </el-select>-->
            <el-radio-group v-if="showType" v-model="type" class="item-component ml10">
                <el-radio :label="1">机台</el-radio>
                <el-radio :label="2">班次</el-radio>
            </el-radio-group>
          <el-select
            style="width: 250px"
            v-if="roleType"
            class="item-component-select ml10"
            v-model="query.roleId"
            clearable
            placeholder="请选择角色"
            filterable
          >
            <el-option
              v-for="item in roleList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
          <el-select
            style="width: 250px"
            v-if="equipment"
            class="item-component-select ml10"
            v-model="query.deviceId"
            clearable
            placeholder="请选择数采设备"
            @change="handleChange"
            filterable
          >
            <el-option
              v-for="item in tableNumber"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
          <el-select
            style="width: 250px"
            v-if="equipment"
            class="item-component-select ml10"
            v-model="query.groupId"
            clearable
            placeholder="请选择班组"
            filterable
          >
            <el-option
              v-for="(item, index) in teamGroup"
              :key="index"
              :label="item.name"
              :value="item.id"
            />
          </el-select>
          <el-select
              style="width: 250px"
              v-if="device"
              class="item-component-select ml10"
              v-model="query.type"
              clearable
              placeholder="请选择设备类型"
              filterable
          >
            <el-option
                v-for="(item, index) in deviceList"
                :key="index"
                :label="item.text"
                :value="item.value"
            />
          </el-select>
        </div>
        <div class="cb-right right-center">
          <el-button
              v-if="downLoading"
              type="primary"
              @click="loading"
          >
            导出
          </el-button>
            <el-button
              type="primary"
              @click="search"
            >
              查询
            </el-button>
        </div>
    </div>

</template>
<script>

export default {
    props:{
      enterpriseId: '',
      dateStart:{
        type: Boolean,
        default: true,
      },
      dateTime:{
        type: Boolean,
        default: false,
      },
      downLoading:{
        type: Boolean,
        default: false,
      },
      showType: {
        type: Boolean,
        default: false,
      },
      roleType: {
        type: Boolean,
        default: false,
      },
      equipment: {
        type: Boolean,
        default: false,
      },
      device: {
        type: Boolean,
        default: false,
      },
      isMultiple: {
        type: Boolean,
        default: false,
      },
      enterpriseList: {
        type: Array,
        default: () => []
      },
      teamGroup: {
        type: Array,
        default: () => []
      },
      tableNumber: {
        type: Array,
        default: () => []
      },
      conditionFrom: {
        type: Object,
        default: () => {}
      },
      historyQuery: {
        type: Object,
        default: () => {}
      }
    },
    data() {
        return {
          roleList: [
            {label: '企业负责人', value: '5a729bb3-f6c7-499b-b3c2-0193a99addda'},
            {label: '生产主管', value: '3644fc5d-ef69-4a30-8dad-6407b3120eed'},
            {label: '设备主管', value: '3411fd45-4143-4b18-ba7c-ca6fe596563a'},
            {label: '印前主管', value: 'afaaeaa3-5e93-44ad-b204-2121f400d436'},
            {label: '印刷主管', value: 'e2c38b03-989c-454b-aa65-7035c41ae1fb'},
            {label: '印刷职员', value: 'a26bdd9d-0c59-4e27-a938-2e845d8192aa'},
            {label: '维修人员', value: '2b771be5-b636-41db-ada4-7164e7b638fc'},
            {label: '人事主管', value: '0ea0531a-94c9-4b57-90cb-7315b8c2b961'}
          ],
          deviceList: [
            {
              value: -1,
              text: '全部'
            },
            {
              value: 1,
              text: '印前设备'
            },
            {
              value: 2,
              text: '印刷设备'
            },
            {
              value: 3,
              text: '印后设备'
            }
          ],
            enterprise: undefined,
            query: {
                startTime: undefined,
                endTime: undefined,
                roleId: '',
                groupId: '',
                deviceId: '',
              type: -1
                // sheetType: this.selectedSheetType,
            },
            type: 1,
            pickerOptionsStart:{
                disabledDate: time => {
                if (this.query.endTime) {
                    return (
                    time.getTime() > new Date(this.query.endTime).getTime() || time.getTime() < new Date(this.query.endTime).getTime() - 100 * 24 * 3600000 || time.getTime(new Date()) > Date.now()
                    );
                }else{
                    return time.getTime(new Date()) > Date.now();
                }
                }
            },
          optionsStart: {
            disabledDate: time => {
              return time.getTime(new Date()) > Date.now();
            }
          },
            pickerOptionsEnd:{
                disabledDate: time => {
                if (this.query.startTime) {
                    return (
                    time.getTime() < new Date(this.query.startTime).getTime() - 8.64e7 || time.getTime() > new Date(this.query.startTime).getTime() + 100 * 24 * 3600000 || time.getTime(new Date()) > Date.now()
                    );
                }else{
                    return time.getTime(new Date()) > Date.now();
                }
                }
            },
        }
    },
    watch:{
      conditionFrom:{
        deep: true,
        handler(val) {
          // console.log(this.historyQuery)
          this.query = this.historyQuery && this.historyQuery.startTime ? {startTime: this.historyQuery.startTime, endTime: this.historyQuery.endTime} : val.query
          // if(this.isMultiple) {
          //   // console.log(this.historyQuery,'9')
          //   if (typeof val.enterprise === 'string') {
          //     this.enterprise = this.historyQuery && this.historyQuery.enterprise ? this.historyQuery.enterprise : val.enterprise.split()
          //   } else {
          //     this.enterprise = this.historyQuery && this.historyQuery.enterprise ? this.historyQuery.enterprise : val.enterprise
          //   }
          // } else {
          //   // console.log(this.historyQuery,'2')
          //   if (typeof val.enterprise === 'string') {
          //     this.enterprise = this.historyQuery && this.historyQuery.enterprise ? this.historyQuery.enterprise : val.enterprise
          //   } else {
          //     this.enterprise = this.historyQuery && this.historyQuery.enterprise ? this.historyQuery.enterprise : val.enterprise[0]
          //   }
          // }
        }
      }
    },
    created() {
      if (this.$store.state.userInfo.teamId) {
        localStorage.setItem('teamIds', this.$store.state.userInfo.teamId)
        this.enterprise = localStorage.getItem('teamIds')
      }
      this.enterprise = localStorage.getItem('teamIds')
      // console.log(9595)
      // this.query.startTime = this.$store.state.condition.startTime
      // this.query.endTime = this.$store.state.condition.endTime
    },
    methods:{
        search(){
          if (this.dateStart) {
            if(!this.query.startTime){
              this.$message({
                message: '请选择开始日期',
                type: 'warning',
              });
              return;
            }
            if(!this.query.endTime){
              this.$message({
                message: '选择结束日期',
                type: 'warning',
              });
              return;
            }
          }
          if (this.roleType) {
            if(!this.query.roleId){
              this.$message({
                message: '请选择角色',
                type: 'warning',
              });
              return;
            }
          }
          if (this.dateTime){
            if(!this.query.date){
              this.$message({
                message: '请选择日期',
                type: 'warning',
              });
              return;
            }
          }
          if (this.equipment) {
            if(!this.query.deviceId){
              this.$message({
                message: '请选择设备',
                type: 'warning',
              });
              return;
            }
            if(!this.query.groupId){
              this.$message({
                message: '请选择班组',
                type: 'warning',
              });
              return;
            }
          }
          if(this.showType){
            this.query.type = this.type
          }else{
            if (!this.query.type) {
              this.query.type = -1
              // delete this.query.type
            }
          }
          this.$emit("search", {query: this.query, enterprise: this.enterprise})
        },
      loading(){
        if (this.dateStart) {
          if(!this.query.startTime){
            this.$message({
              message: '请选择开始日期',
              type: 'warning',
            });
            return;
          }
          if(!this.query.endTime){
            this.$message({
              message: '选择结束日期',
              type: 'warning',
            });
            return;
          }
        }
        if (this.roleType) {
          if(!this.query.roleId){
            this.$message({
              message: '请选择角色',
              type: 'warning',
            });
            return;
          }
        }
        if (this.dateTime){
          if(!this.query.date){
            this.$message({
              message: '请选择日期',
              type: 'warning',
            });
            return;
          }
        }
        if (this.equipment) {
          if(!this.query.deviceId){
            this.$message({
              message: '请选择设备',
              type: 'warning',
            });
            return;
          }
          if(!this.query.groupId){
            this.$message({
              message: '请选择班组',
              type: 'warning',
            });
            return;
          }
        }
        if(this.showType){
          this.query.type = this.type
        }else{
          if (!this.query.type) {
            this.query.type = -1
            // delete this.query.type
          }
        }
        this.$emit("loading", {query: this.query, enterprise: this.enterprise})
      },
        startTimeChange(val){
          if(this.query.endTime && new Date(val).getTime() > new Date(this.query.endTime).getTime()){
            this.query.startTime = null
          }else{
            this.query.startTime = val
          }
        },
        endTimeChange(val){
          // 最长时间跨度100天
          if(this.query.startTime && new Date(val).getTime() - new Date(this.query.startTime).getTime() > 100 * 24 * 60 * 60 *1000){
            this.query.endTime = null
          }else{
            this.query.endTime = val
          }
        },
        handleChange(e){
          if ( !this.query.groupId) {
            if (this.query.deviceId) {
                this.$emit('changeId',e)
                this.query.groupId = ''
              return
            } else {
              this.query.groupId = ''
              this.$emit('changeId',e)
            }
          } else {
            this.$emit('changeId',e)
            this.query.groupId = ''
          }
        },
    }
}
</script>
<style lang="scss" scoped>
    .right-center{
        margin: auto 0;
    }
    .control-box-width{

      width: 100%;
      .left{
        width: 89%;
        ::v-deep .el-select__tags-text {
          display: inline-block;
          max-width: 180px;
          overflow: hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
        }
        ::v-deep .el-select .el-tag__close.el-icon-close{
          top: -7px;
        }
        .item-component{
          //width: 21%;
          min-width: 160px;
          margin-bottom: 5px;
        }
        .item-component-select{
          //width: 30%;
          min-width: 260px;
          margin-bottom: 5px;
        }
      }

    }

    .ml10{
        margin-left: 2%;
    }
</style>
