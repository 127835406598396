<template>
  <!--  日清周清：印量分析-->
  <div>
    <div class="section">
      <condition :enterpriseId="enterpriseId" @changeId="changeIdValue" :dateTime="true" :historyQuery = "historyQuery" :enterpriseList="enterpriseList" :tableNumber="tableNumber" :teamGroup="teamGroup" :dateStart="false" :equipment="true" :conditionFrom="conditionFrom" @search="search"></condition>
    </div>
    <div class="content"  style="padding: 0 0 0 20px;height: 64vh;overflow-y: auto;">
      <div class="table-box">
          <el-table
            ref="tableData"
            show-summary
            :data="tableData"
            border
            style="width: 100%;"
            height="565"
          >
            <el-table-column
              prop="number"
              label="序号"
              align="left"
              width="50"
            ></el-table-column>
            <el-table-column
              prop="name"
              label="设备名称"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="startDate"
              label="开始时间"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="endDate"
              label="结束时间"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="allValue"
              label="总印张(张)"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="okValue"
              label="有效印张(张)"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="effecPrintTime"
              label="有效时长(分钟)"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="noValue"
              label="过版印张(张)"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="testPrintTime"
              label="过版时长(分钟)"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="wuValue"
              label="无效印张(张)"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="invalidPrintTime"
              label="无效时长(分钟)"
              align="center"
            ></el-table-column>
          </el-table>

      </div>
    </div>
  </div>
</template>

<script>
import condition from './condition'
import API from '@/api';
export default {
name: "watermarkingCapacity",
  props: {
    enterpriseIdValue: '',
    enterpriseList: {
      type: Array,
      default: () => []
    },
    // teamGroup:{
    //   type: Array,
    //   default: () => []
    // },
    // tableNumber:{
    //   type: Array,
    //   default: () => []
    // },
    conditionFrom: {
      type: Object,
      default: () => {}
    }
  },
  components: { condition },
  data() {
    return {
      historyQuery:{},
      filter: {
        date: undefined,
        startTime: undefined,
        endTime: undefined,
        orderId: '',
        teamId: '',
        teamGroupId: '',
        equipmentDate: '',
        groupId: '',
        deviceId: ''
      },
      tableImport: {
        count: 1000000,
        page: 1,
        teamId: '',
        name: '',
        icollection: 1,
        type: -1,
      },
      tableNumber: [], // 数采设备
      teamGroup: [], // 班组
      enterpriseId: '',
      tableData: [],
      pickerOptions: {
        disabledDate: time => {
          if (this.filter.endTime) {
            return (
              time.getTime() > new Date(this.filter.endTime).getTime() || time.getTime() < new Date(this.filter.endTime).getTime() - 100 * 24 * 3600000 || time.getTime(new Date()) > Date.now()
            );
          }else{
            return time.getTime(new Date()) > Date.now();
          }
        }
      },
      pickerOptionsEnd:{
        disabledDate: time => {
          if (this.filter.startTime) {
            return (
              time.getTime() < new Date(this.filter.startTime).getTime() - 8.64e7 || time.getTime() > new Date(this.filter.startTime).getTime() + 100 * 24 * 3600000 || time.getTime(new Date()) > Date.now()
            );
          }else{
            return time.getTime(new Date()) > Date.now();
          }
        }
      },
      // 柱形颜色
      // barColor: [
      //   // eslint-disable-next-line new-cap,no-new-require
      //   new require('echarts').graphic.LinearGradient(0, 0, 0, 1, [
      //     { offset: 0, color: '#FA460D' },
      //     { offset: 1, color: '#F7B515' },
      //   ]),
      // ],
    }
  },
  mounted() {

  },
watch:{
  conditionFrom: {
    deep: true,
    handler(val) {
      // console.log(val)
      if (typeof val.enterprise === 'object') {
        if (this.tableNumber.length < 1) {
          // this.tableImport.teamId
          API.CompanyPreserveList({...this.tableImport, teamId: val.enterprise[0]})
            .then((res) => {
              this.tableNumber = res.message.data.list;
              // this.tableImport.total = res.totalCount;
            })
            .catch((mgs) => {
              console.log(mgs);
            });
        }
      } else {
        if (this.tableNumber.length < 1) {
          API.CompanyPreserveList({...this.tableImport, teamId: val.enterprise})
            .then((res) => {
              this.tableNumber = res.message.data.list;
              // this.tableImport.total = res.totalCount;
            })
            .catch((mgs) => {
              console.log(mgs);
            });
        }
      }
    }
  }
},
  created() {
  // this.getList()
    if (this.tableNumber.length < 1) {
      // this.tableImport.teamId
      API.CompanyPreserveList({...this.tableImport, teamId: localStorage.getItem('teamIds')})
          .then((res) => {
            this.tableNumber = res.message.data.list;
            // this.tableImport.total = res.totalCount;
          })
          .catch((mgs) => {
            console.log(mgs);
          });
    }
  },
  methods: {
  getList() {
    API.getDateDetail({...this.filter}).then(res => {
      this.tableData = res.message.data
    })
  },
    changeIdValue(value) {
      if (value !== '111') {
        if (value) {
          API.getClassDateList({deviceId: value}).then((response) => {
            console.log(response)
            this.teamGroup = response.message.data;
          });
        } else {
          this.teamGroup = []
        }
      }
    },
    search(condition) {
      // console.log(condition)
      if (!this.$store.state.condition.enterprise) {
        // console.log(this.$store.state.condition)
        this.$store.commit('setFormDate',condition)
      }
      this.echartList = []
      this.showDisplay = false
      this.filter = {
        ...condition.query,
        teamId:condition.enterprise
        // deviceId: condition.query.deviceId,
        // groupId: condition.query.groupId
      }
      this.historyQuery = {
        startTime: condition.query.startTime,
        endTime: condition.query.endTime,
        enterprise: condition.enterprise
        // deviceId: condition.query.deviceId,
        // groupId: condition.query.groupId
      }
      this.getList()
      this.showDisplay = true
    },
  }
}
</script>

<style scoped lang="scss">
::v-deep .table-box table thead th .cell{
  color: #606266;
}
</style>
