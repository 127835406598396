<template>
    <div class="detailDialog">
      <el-dialog
        :title="detail.deviceName"
        :visible.sync="dialogVisibleVal"
        width="50%"
        :close-on-click-modal="false"
        @close="handleDialogCancelClick"
        footer="null"
      >
        <div class="table-box">
            <el-table :data="tableData" border>
                <el-table-column
                  type="index"
                  :index="indexMethod"
                  label="订单序号"
                  width="100"
                >
                </el-table-column>
                <el-table-column
                    prop="ratedTime"
                    label="额定换型时间"
                >
                </el-table-column>
                <el-table-column
                    prop="time"
                    label="换型超出时间"
                >
                </el-table-column>
                <el-table-column
                    prop="reason"
                    label="换型异常原因"
                >
                </el-table-column>
            </el-table>
        </div>
      </el-dialog>
    </div>
  </template>

  <script>
  // import {getAbnormalShutdownDetail} from '@/api/dayWeekReport'

  export default {
    props: {
      dialogVisible: Boolean,
      detail: Object,
      query: Object
    },
    components: {
    },
    data() {
      return {
        tableData: []
      };
    },
    created: function() {},
    watch: {
      dialogVisible: function(val) {
        // getAbnormalShutdownDetail({
        //   startTime: this.query.startTime,
        //   endTime: this.query.endTime,
        //   deviceId: this.detail.deviceId
        // }).then(res =>{
        //   if(res){
        //     this.tableData = res
        //   }
        // })
      }
    },
    computed: {
      dialogVisibleVal: {
        get() {
          return this.dialogVisible;
        },
        set() {}
      }
    },
    methods: {
      indexMethod(index){
        return index + 1;
      },
      handleDialogCancelClick: function() {
        this.$emit("close");
      }
    }
  };
  </script>
  <style lang="scss" scoped>
  </style>
